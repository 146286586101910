var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative" }, [
    _c("p", { staticClass: "text-lg font-medium text-blue-gray-900" }, [
      _vm._v("Preview")
    ]),
    _c("p", { staticClass: "mt-2 text-sm text-gray-500" }, [
      _vm._v(
        " Popup will show only once to the customer, If you like to test please use incognito window. "
      )
    ]),
    _c(
      "div",
      {
        staticClass:
          "bg-white h-96 shadow-md rounded-md mt-4   bg-gray-500 opacity-1"
      },
      [
        _vm.config && _vm.config.styles
          ? _c("div", { attrs: { id: "magic-bar-wrapper" } }, [
              _vm.config.widgetType === "banner"
                ? _c("div", { attrs: { id: "magicbar-content-container" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "flex justify-center items-center",
                        style: {
                          backgroundColor:
                            _vm.config.styles.popupBackgroundColor,
                          color: _vm.config.styles.popupTextColor
                        },
                        attrs: { id: "magicbar" }
                      },
                      [
                        _c("img", {
                          staticClass: "h-6 w-6 mr-2",
                          attrs: {
                            src:
                              "https://configs.carthike.com/images/instalogo.png"
                          }
                        }),
                        !_vm.isActionCompleted
                          ? _c("span", [_vm._v(_vm._s(_vm.getCTA(_vm.config)))])
                          : _c("span", [
                              _vm._v(
                                _vm._s(_vm.getGoalCompletedText(_vm.config))
                              )
                            ]),
                        _c(
                          "span",
                          {
                            staticClass:
                              "flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
                          },
                          [
                            !_vm.isActionCompleted
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5",
                                    style: {
                                      backgroundColor:
                                        _vm.config.styles.buttonBackgroundColor,
                                      color: _vm.config.styles.buttonTextColor
                                    },
                                    attrs: { type: "button" },
                                    on: { click: _vm.openInstagram }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.config.discount.followButtonLabel
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "hidden sm:block absolute top-0 right-0 pt-4 pr-4"
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150",
                                    attrs: {
                                      type: "button",
                                      "aria-label": "Close"
                                    },
                                    on: { click: _vm.close }
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "h-6 w-6",
                                        attrs: {
                                          fill: "none",
                                          viewBox: "0 0 24 24",
                                          stroke: "currentColor"
                                        }
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                            "stroke-width": "2",
                                            d: "M6 18L18 6M6 6l12 12"
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ])
          : _vm._e(),
        _vm.config && _vm.config.styles
          ? _c(
              "div",
              { staticClass: "h-full", attrs: { id: "magic-bar-wrapper" } },
              [
                _vm.config.widgetType === "popup"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "relative bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center z-10 h-full"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "relative bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6",
                            style: {
                              backgroundColor:
                                _vm.config.styles.popupBackgroundColor,
                              color: _vm.config.styles.popupTextColor
                            },
                            attrs: {
                              role: "dialog",
                              "aria-modal": "true",
                              "aria-labelledby": "modal-headline"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "hidden sm:block absolute top-0 right-0 pt-4 pr-4"
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150",
                                    attrs: {
                                      type: "button",
                                      "aria-label": "Close"
                                    },
                                    on: { click: _vm.close }
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "h-6 w-6",
                                        attrs: {
                                          fill: "none",
                                          viewBox: "0 0 24 24",
                                          stroke: "currentColor"
                                        }
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round",
                                            "stroke-width": "2",
                                            d: "M6 18L18 6M6 6l12 12"
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ]
                            ),
                            !_vm.isActionCompleted
                              ? _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "sm:ml-4 mt-4 text-left" },
                                    [
                                      _vm._m(0),
                                      _c(
                                        "p",
                                        { staticClass: "text-center mt-2" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.getCTA(_vm.config)) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-5 sm:mt-4 sm:flex justify-center"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5",
                                              style: {
                                                backgroundColor:
                                                  _vm.config.styles
                                                    .buttonBackgroundColor,
                                                color:
                                                  _vm.config.styles
                                                    .buttonTextColor
                                              },
                                              attrs: { type: "button" },
                                              on: { click: _vm.openInstagram }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.config.discount
                                                      .followButtonLabel
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              : _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "sm:ml-4 mt-4 text-left" },
                                    [
                                      _c("p", { staticClass: "text-center" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getGoalCompletedText(
                                                _vm.config
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  )
                                ])
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex justify-center" }, [
      _c("img", {
        staticClass: "h-6 w-6",
        attrs: { src: "https://configs.carthike.com/images/instalogo.png" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }