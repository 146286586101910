var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "relative inline-block text-left" },
      [
        _c("div", [
          _c("span", { staticClass: "rounded-md shadow-sm" }, [
            _c(
              "button",
              {
                staticClass:
                  "flex items-center text-gray-400 hover:text-gray-600",
                on: {
                  click: function($event) {
                    _vm.isOpen = !_vm.isOpen
                  }
                }
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "h-5 w-5",
                    attrs: { fill: "currentColor", viewBox: "0 0 20 20" }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
                      }
                    })
                  ]
                )
              ]
            )
          ])
        ]),
        _c(
          "transition",
          {
            attrs: {
              "enter-active-class":
                "transition ease-out duration-100 transform",
              "enter-class": "opacity-0 scale-95",
              "enter-to-class": "opacity-100 scale-100",
              "leave-active-class": "transition ease-in duration-75 transform",
              "leave-class": "opacity-100 scale-100",
              "leave-to-class": "opacity-0 scale-95"
            }
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isOpen,
                    expression: "isOpen"
                  }
                ],
                staticClass:
                  "origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg z-10"
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "rounded-md bg-white shadow-xs",
                    attrs: {
                      role: "menu",
                      "aria-orientation": "vertical",
                      "aria-labelledby": "options-menu"
                    }
                  },
                  [
                    _c("div", { staticClass: "py-1" }, [
                      _c(
                        "a",
                        {
                          staticClass:
                            "block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900",
                          attrs: { role: "menuitem" }
                        },
                        [
                          _c("div", {
                            staticClass: "hidden md:block",
                            attrs: { id: "google_translate_element" }
                          })
                        ]
                      ),
                      _c(
                        "a",
                        {
                          staticClass:
                            "block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900",
                          attrs: {
                            role: "menuitem",
                            href: "mailto:help@carthike.com"
                          }
                        },
                        [_vm._v(" Contact us ")]
                      )
                    ]),
                    _c("div", { staticClass: "border-t border-gray-100" })
                  ]
                )
              ]
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }