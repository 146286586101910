<template>
  <div class="h-full flex">
    <div class="flex-1 min-w-0 flex flex-col overflow-hidden">
      <main class="flex-1 flex overflow-hidden">
        <div class="flex-1 flex flex-col overflow-y-auto xl:overflow-hidden">
          <div class="flex-1 flex xl:overflow-hidden">
            <nav
              aria-label="Sections"
              class=" flex-shrink-0 w-96 bg-white border-r border-blue-gray-200 xl:flex xl:flex-col"
            >
              <div
                class="flex-shrink-0 h-16 px-6 border-b border-blue-gray-200 flex items-center"
              >
                <p class="text-lg font-medium text-blue-gray-900">Customize</p>
              </div>
              <div class="flex-1 min-h-0 overflow-y-auto">
                <Customize :config="config" />
              </div>
            </nav>

            <!-- Main content -->
            <div class="flex-1 xl:overflow-y-auto">
              <div class="max-w-3xl mx-auto py-4 px-4 ">
                <PreviewBar :config="config" />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Customize from "../components/Settings/Customize.vue";
import PreviewBar from "../components/Common/PreviewBar.vue";

export default {
  components: {
    Customize,
    PreviewBar
  },
  data() {
    return {
      config: {},

      isLoading: false,
      showForm: false
    };
  },
  created() {
    this.getAppConfig();
  },
  methods: {
    getAppConfig() {
      this.isLoading = true;
      this.$store.dispatch("getAppConfig").then(response => {
        this.config = response.config;
        this.isLoading = false;
        this.showForm = true;
        console.log(response);
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
