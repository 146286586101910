import { render, staticRenderFns } from "./Customize.vue?vue&type=template&id=48972be7&scoped=true&"
import script from "./Customize.vue?vue&type=script&lang=js&"
export * from "./Customize.vue?vue&type=script&lang=js&"
import style0 from "./Customize.vue?vue&type=style&index=0&id=48972be7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48972be7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/sivaram/work/carthike/instaboost-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('48972be7')) {
      api.createRecord('48972be7', component.options)
    } else {
      api.reload('48972be7', component.options)
    }
    module.hot.accept("./Customize.vue?vue&type=template&id=48972be7&scoped=true&", function () {
      api.rerender('48972be7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Settings/Customize.vue"
export default component.exports