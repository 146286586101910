var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.config.discount
      ? _c("div", { staticClass: "py-2 max-w-3xl mx-auto" }, [
          _c(
            "div",
            { staticClass: "px-4 py-5  sm:p-6" },
            [
              _c("div", { staticClass: "md:grid md:grid-cols-3 md:gap-6" }, [
                _c("div", { staticClass: "mt-5 md:mt-0 md:col-span-3" }, [
                  _c("fieldset", [
                    _c(
                      "legend",
                      {
                        staticClass:
                          "text-base leading-6 font-medium text-gray-900"
                      },
                      [_vm._v(" Enable ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "mt-4 flex flex-wrap" },
                      [
                        _c("VSwitch", {
                          attrs: { value: _vm.config.isEnabled },
                          on: { input: _vm.handleSwitch }
                        })
                      ],
                      1
                    ),
                    _c("p", { staticClass: "mt-2 text-sm text-gray-500" }, [
                      _vm._v(
                        " Once the popup is enabled it will be shown to all the customers. "
                      )
                    ])
                  ]),
                  _c("fieldset", { staticClass: "mt-2" }, [
                    _c(
                      "legend",
                      {
                        staticClass:
                          "text-base leading-6 font-medium text-gray-900"
                      },
                      [
                        _vm._v(" Using Online Store 2.0? "),
                        _c(
                          "a",
                          {
                            staticClass: "text-blue-500 cursor-pointer",
                            on: { click: _vm.openEmbedLink }
                          },
                          [_vm._v(" Click here")]
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "grid grid-cols-6 gap-6 mt-4" }, [
                    _c("div", { staticClass: "col-span-6 sm:col-span-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "block text-sm font-medium text-gray-700"
                        },
                        [_vm._v("Instagram profile name")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.config.instagramHandle,
                            expression: "config.instagramHandle"
                          }
                        ],
                        staticClass:
                          "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border p-2",
                        attrs: { type: "text" },
                        domProps: { value: _vm.config.instagramHandle },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.config,
                              "instagramHandle",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "col-span-6 sm:col-span-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "block text-sm font-medium text-gray-700"
                        },
                        [_vm._v("Widget Type")]
                      ),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.config.widgetType,
                              expression: "config.widgetType"
                            }
                          ],
                          staticClass:
                            "mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md border",
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.config,
                                "widgetType",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        _vm._l(_vm.designOptions, function(option, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: option.value } },
                            [_vm._v(_vm._s(option.label))]
                          )
                        }),
                        0
                      )
                    ]),
                    _c("div", { staticClass: "col-span-6 sm:col-span-3" }, [
                      _c(
                        "label",
                        {
                          staticClass: "block text-sm font-medium text-gray-700"
                        },
                        [_vm._v("Discount percentage")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.config.discount.discountPercent,
                            expression: "config.discount.discountPercent"
                          }
                        ],
                        staticClass:
                          "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border p-2",
                        attrs: { type: "number" },
                        domProps: {
                          value: _vm.config.discount.discountPercent
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.config.discount,
                              "discountPercent",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "col-span-6 sm:col-span-3" }, [
                      _c(
                        "label",
                        {
                          staticClass: "block text-sm font-medium text-gray-700"
                        },
                        [_vm._v("Popup delay seconds")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.config.popupDelaySeconds,
                            expression: "config.popupDelaySeconds"
                          }
                        ],
                        staticClass:
                          "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border p-2",
                        attrs: { type: "number" },
                        domProps: { value: _vm.config.popupDelaySeconds },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.config,
                              "popupDelaySeconds",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "col-span-6 sm:col-span-6" }, [
                      _c(
                        "label",
                        {
                          staticClass: "block text-sm font-medium text-gray-700"
                        },
                        [_vm._v("Coupon Code")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.config.discount.couponCode,
                            expression: "config.discount.couponCode"
                          }
                        ],
                        staticClass:
                          "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border p-2",
                        attrs: { type: "text" },
                        domProps: { value: _vm.config.discount.couponCode },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.config.discount,
                              "couponCode",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _c("p", { staticClass: "mt-2 text-sm text-gray-500" }, [
                        _vm._v(" Create coupon codes from your "),
                        _c(
                          "a",
                          {
                            staticClass: "text-blue-700",
                            attrs: { href: _vm.discountUrl, target: "_blank" }
                          },
                          [_vm._v("Shopify admin > Discounts")]
                        )
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "mt-4 sm:mt-0 sm:col-span-2" }, [
                    _c(
                      "div",
                      { staticClass: "mt-8 border-t border-gray-200 pt-5" },
                      [
                        _c("div", { staticClass: "flex justify-end" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "ml-3 inline-flex rounded-md shadow-sm"
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out",
                                  attrs: { type: "submit" },
                                  on: { click: _vm.save }
                                },
                                [_vm._v(" Save ")]
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ]),
              _c("Notification", {
                attrs: {
                  text: _vm.notificationText,
                  show: _vm.showNotification
                }
              })
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }