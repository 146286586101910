var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass:
        "bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline",
      class: { "bg-indigo-600": _vm.value, "bg-gray-200": !_vm.value },
      attrs: { role: "checkbox", tabindex: "0", "aria-checked": "false" },
      on: {
        click: function($event) {
          return _vm.$emit("input", !_vm.value)
        }
      }
    },
    [
      _c("span", {
        staticClass:
          "translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200",
        class: { "translate-x-5": _vm.value, "translate-x-0": !_vm.value },
        attrs: { "aria-hidden": "true" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }