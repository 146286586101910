<template>
  <div class="relative">
    <p class="text-lg font-medium text-blue-gray-900">Preview</p>
    <p class="mt-2 text-sm text-gray-500">
      Popup will show only once to the customer, If you like to test please use
      incognito window.
    </p>
    <div
      class="bg-white h-96 shadow-md rounded-md mt-4   bg-gray-500 opacity-1"
    >
      <div v-if="config && config.styles" id="magic-bar-wrapper">
        <div
          v-if="config.widgetType === 'banner'"
          id="magicbar-content-container"
        >
          <div
            id="magicbar"
            class="flex justify-center items-center"
            :style="{
              backgroundColor: config.styles.popupBackgroundColor,
              color: config.styles.popupTextColor
            }"
          >
            <img
              src="https://configs.carthike.com/images/instalogo.png"
              class="h-6 w-6 mr-2"
            />
            <span v-if="!isActionCompleted">{{ getCTA(config) }}</span>
            <span v-else>{{ getGoalCompletedText(config) }}</span>
            <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
              <button
                v-if="!isActionCompleted"
                type="button"
                class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                :style="{
                  backgroundColor: config.styles.buttonBackgroundColor,
                  color: config.styles.buttonTextColor
                }"
                @click="openInstagram"
              >
                {{ config.discount.followButtonLabel }}
              </button>
              <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  @click="close"
                  type="button"
                  class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                  aria-label="Close"
                >
                  <svg
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div v-if="config && config.styles" id="magic-bar-wrapper" class="h-full">
        <div
          v-if="config.widgetType === 'popup'"
          class="relative bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center z-10 h-full"
        >
          <!--
    Background overlay, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->

          <!--
    Modal panel, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      To: "opacity-100 translate-y-0 sm:scale-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100 translate-y-0 sm:scale-100"
      To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  -->
          <div
            class="relative bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6"
            :style="{
              backgroundColor: config.styles.popupBackgroundColor,
              color: config.styles.popupTextColor
            }"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                @click="close"
                type="button"
                class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                aria-label="Close"
              >
                <svg
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <div v-if="!isActionCompleted">
              <div class="sm:ml-4 mt-4 text-left">
                <div class="flex justify-center">
                  <img
                    src="https://configs.carthike.com/images/instalogo.png"
                    class="h-6 w-6"
                  />
                </div>
                <p class="text-center mt-2">
                  {{ getCTA(config) }}
                </p>
              </div>
              <div class="mt-5 sm:mt-4 sm:flex justify-center">
                <span
                  class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
                >
                  <button
                    type="button"
                    class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    :style="{
                      backgroundColor: config.styles.buttonBackgroundColor,
                      color: config.styles.buttonTextColor
                    }"
                    @click="openInstagram"
                  >
                    {{ config.discount.followButtonLabel }}
                  </button>
                </span>
              </div>
            </div>
            <div v-else>
              <div class="sm:ml-4 mt-4 text-left">
                <p class="text-center">
                  {{ getGoalCompletedText(config) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      isActionCompleted: false
    };
  },
  methods: {
    getCTA(config) {
      const cta = config.discount.cta.replace(
        "{discount_percent}",
        config.discount.discountPercent
      );
      return cta;
    },
    close() {},
    getGoalCompletedText(config) {
      let goalCompletionText = config.discount.goalCompletionText.replace(
        "{discount_percent}",
        config.discount.discountPercent
      );
      goalCompletionText = goalCompletionText.replace(
        "{coupon_code}",
        config.discount.couponCode
      );
      return goalCompletionText;
    },
    openInstagram() {
      const url = `https://instagram.com/${this.config.instagramHandle}`;
      window.open(url, "_blank");
      this.isActionCompleted = true;
    }
  }
};
</script>

<style lang="scss" scoped>
#magic-bar-wrapper {
  display: block;
  color: inherit;
}
#magicbar-content-container {
  opacity: 1;
  margin: 0px;
  padding: 0px;
  left: 0px;
  height: 58px;
  width: 100%;
  z-index: 100000;
  top: 0px;
}
#magicbar {
  text-align: center;
  margin: 0px;
  padding: 14px 10px;
  left: 0px;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  border: none;
  position: absolute;
  background-color: black;
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 30px;
}
</style>
