<template>
  <div>
    <div class=" max-w-6xl mx-8 py-10">
      <h1><b>Privacy Policy</b></h1>
      <p>
        Your privacy is very important to us. At HelpNinja we have a few
        fundamental principles that we follow:
      </p>
      <p>
        We don’t ask you for personal information unless we truly need it. (We
        can’t stand services that ask you for things like your gender or income
        level for no apparent reason.)
      </p>
      <p>
        We don’t share your personal information with anyone except to comply
        with the law, develop our products, or protect our rights.
      </p>
      <p>
        We don’t store personal information on our servers unless required for
        the on-going operation of our site.
      </p>
      <p>
        Revmakx LLC (“HelpNinja”) operates several websites including
        helpninja.com. It is HelpNinja’s policy to respect your privacy
        regarding any information we may collect while operating our websites.
      </p>
      <h5><b>Website Visitors</b></h5>
      <p>
        Like most website operators, HelpNinja collects
        non-personally-identifying information of the sort that web browsers and
        servers typically make available, such as the browser type, language
        preference, referring site, and the date and time of each visitor
        request. HelpNinja purpose in collecting non-personally identifying
        information is to better understand how HelpNinja visitors use its
        website. From time to time, HelpNinja may release
        non-personally-identifying information in the aggregate, e.g., by
        publishing a report on trends in the usage of its website.
      </p>
      <p>
        HelpNinja also collects potentially personally-identifying information
        like Internet Protocol (IP) addresses for logged in users and for users
        leaving comments on our blogs. HelpNinja only discloses logged in user
        and commenter IP addresses under the same circumstances that it uses and
        discloses personally-identifying information as described below, except
        that blog commenter IP addresses are visible and disclosed to the
        administrators of the blog where the comment was left.
      </p>
      <h5>
        <b>Gathering of Personally-Identifying Information</b>
      </h5>
      <p>
        Certain visitors to HelpNinja websites choose to interact with HelpNinja
        in ways that require HelpNinja to gather personally-identifying
        information. The amount and type of information that HelpNinja gathers
        depends on the nature of the interaction. For example, we ask visitors
        who comment on our blog to provide a username and email address. Those
        who wish to receive HelpNinja updates via email, we collect their
        emails. For example, when you to sign up for an account, we ask for
        personal information, like your name, email address, telephone number or
        credit card to store with your account. When visitors comment on our
        blog, we ask them to provide a username and email address. For those who
        wish to receive HelpNinja updates via email, we collect their emails.
      </p>
      <p>
        In each case, HelpNinja collects such information only insofar as is
        necessary or appropriate to fulfill the purpose of the visitor’s
        interaction with HelpNinja. HelpNinja does not disclose
        personally-identifying information other than as described below. And
        visitors can always refuse to supply personally-identifying information,
        with the caveat that it may prevent them from engaging in certain
        website-related activities.
      </p>
      <h5>
        <b>Aggregated Statistics</b>
      </h5>
      <p>
        HelpNinja may collect statistics about the behavior of visitors to its
        websites. For instance, HelpNinja may monitor the most popular pages on
        the helpninja.com site or use spam screened by the Akismet service to
        help identify spam. HelpNinja may display this information publicly or
        provide it to others. However, HelpNinja does not disclose
        personally-identifying information other than as described below.
      </p>
      <h5><b>Information We Share</b></h5>
      <p>
        HelpNinja discloses potentially personally-identifying and
        personally-identifying information only to those of its employees,
        contractors and affiliated organizations that (i) need to know that
        information in order to process it on HelpNinja behalf or to provide
        services available at HelpNinja websites, and (ii) that have agreed not
        to disclose it to others. Some of those employees, contractors and
        affiliated organizations may be located outside of your home country; by
        using HelpNinja websites, you consent to the transfer of such
        information to them.
      </p>
      <p>
        HelpNinja will not rent or sell potentially personally-identifying and
        personally-identifying information to anyone.
      </p>
      <p>
        Other than to its employees, contractors and affiliated organizations,
        as described above, HelpNinja discloses potentially
        personally-identifying and personally-identifying information only in
        response to a subpoena, court order or other governmental request, or
        when HelpNinja believes in good faith that disclosure is reasonably
        necessary to protect the property or rights of HelpNinja, third parties
        or the public at large.
      </p>
      <p>
        If you are a registered user of an HelpNinja website and have supplied
        your email address, HelpNinja may occasionally send you an email to tell
        you about new features, solicit your feedback, or just keep you up to
        date with what’s going on with HelpNinja and our products. We primarily
        use our various product blogs to communicate this type of information,
        so we expect to keep this type of email to a minimum.
      </p>
      <p>
        If you send us a request (for example via a support email or via one of
        our feedback mechanisms), we reserve the right to publish it in order to
        help us clarify or respond to your request or to help us support other
        users.
      </p>
      <p>
        HelpNinja takes all measures reasonably necessary to protect against the
        unauthorized access, use, alteration or destruction of potentially
        personally-identifying and personally-identifying information, as
        discussed below.
      </p>
      <h5><b>Information Security</b></h5>
      <p>
        We work hard to protect our users from unauthorized access to or
        unauthorized alteration, disclosure, or destruction of information we
        hold, in particular:
      </p>
      <p>We encrypt all of our services using SSL.</p>
      <p>
        We review our information collection, storage, and processing
        procedures, including physical security measures, to guard against
        unauthorized access to systems.
      </p>
      <p>
        We restrict access to personal information to HelpNinja employees,
        contractors, and agents who need to know that information in order to
        process it for us, and who are subject to strict contractual
        confidentiality obligations and may be disciplined or terminated if they
        fail to meet these obligations.
      </p>
      <h5><b>Cookies</b></h5>
      <p>
        A cookie is a string of information that a website stores on a visitor’s
        computer, and that the visitor’s browser provides to the website each
        time the visitor returns. HelpNinja uses cookies to help HelpNinja
        identify and track visitors, their usage of HelpNinja website, and their
        website access preferences. HelpNinja visitors who do not wish to have
        cookies placed on their computers should set their browsers to refuse
        cookies before using HelpNinja websites, with the drawback that certain
        features of HelpNinja websites may not function properly without the aid
        of cookies.
      </p>
      <h5><b>Business Transfers</b></h5>
      <p>
        If HelpNinja, or substantially all of its assets were acquired, or in
        the unlikely event that HelpNinja goes out of business or enters
        bankruptcy, user information would be one of the assets that is
        transferred or acquired by a third party. You acknowledge that such
        transfers may occur, and that any acquirer of HelpNinja may continue to
        use your personal information as set forth in this policy.
      </p>
      <h5><b>Ads</b></h5>
      <p>
        Ads appearing on any of our websites may be delivered to users by
        advertising partners, who may set cookies. These cookies allow the ad
        server to recognize your computer each time they send you an online
        advertisement to compile information about you or others who use your
        computer. This information allows ad networks to, among other things,
        deliver targeted advertisements that they believe will be of most
        interest to you. This Privacy Policy covers the use of cookies by
        HelpNinja and does not cover the use of cookies by any advertisers.
      </p>
      <h5><b>Comments</b></h5>
      <p>
        Comments and other content submitted to Disqus anti-spam service are not
        saved on our servers unless they were marked as false positives, in
        which case we store them long enough to use them to improve the service
        to avoid future false positives.
      </p>
      <h5>
        <b>External services where the data is shared</b>
      </h5>
      <p>
        Google Analytics – to track analytics of the account
      </p>
      <p>Firebase – To store real-time data sets</p>
      <p>Mailchimp – To send update and marketing emails</p>
      <p>Amazon – Cloud hosting provider</p>
      <h5>
        <b style="font-size: 1.2rem; font-style: inherit;"
          >When this Privacy Policy Applies</b
        >
      </h5>
      <p>
        Our Privacy Policy applies to all of the services offered by HelpNinja
        and its affiliates, including services HelpNinja provides on mobile
        devices, and services offered on other sites, but excludes services that
        have separate privacy policies that do not incorporate this Privacy
        Policy.
      </p>
      <p>
        Our Privacy Policy does not apply to services offered by other companies
        or individuals, including other sites linked to our services. Our
        Privacy Policy does not cover the information practices of other
        companies and organizations who advertise our services, and who may use
        cookies, pixel tags, and other technologies to serve and offer relevant
        ads.
      </p>
      <h5>
        <b>Compliance and cooperation with regulatory authorities</b>
      </h5>
      <p>
        We regularly review our compliance with our Privacy Policy. We also
        adhere to several self-regulatory frameworks. When we receive formal
        written complaints, we will contact the person who made the complaint to
        follow up. We work with the appropriate regulatory authorities,
        including local data protection authorities, to resolve any complaints
        regarding the transfer of personal data that we cannot resolve with our
        users directly.
      </p>
      <h5>
        <b>Privacy Policy Changes</b>
      </h5>
      <p>
        Although most changes are likely to be minor, HelpNinja may change its
        Privacy Policy from time to time, and in HelpNinja sole discretion.
        HelpNinja encourages visitors to frequently check this page for any
        changes to its Privacy Policy. Your continued use of this site after any
        change in this Privacy Policy will constitute your acceptance of such
        change.
      </p>
      <h5><b>Contact</b></h5>
      <p>
        If you have any questions regarding this Privacy Policy, or your
        dealings with our website, please contact us at help@helpninja.com
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
