<template>
  <div>
    <div v-if="config.discount" class="py-2 max-w-3xl mx-auto">
      <div class="px-4 py-5  sm:p-6">
        <div class="md:grid md:grid-cols-3 md:gap-6">
          <div class="mt-5 md:mt-0 md:col-span-3">
            <fieldset>
              <legend class="text-base leading-6 font-medium text-gray-900">
                Enable
              </legend>

              <div class="mt-4 flex flex-wrap">
                <VSwitch :value="config.isEnabled" @input="handleSwitch" />
              </div>
              <p class="mt-2 text-sm text-gray-500">
                Once the popup is enabled it will be shown to all the customers.
              </p>
            </fieldset>
            <fieldset class="mt-2">
              <legend class="text-base leading-6 font-medium text-gray-900">
                Using Online Store 2.0?
                <a class="text-blue-500 cursor-pointer" @click="openEmbedLink">
                  Click here</a
                >
              </legend>
            </fieldset>
            <div class="grid grid-cols-6 gap-6 mt-4">
              <div class="col-span-6 sm:col-span-6">
                <label class="block text-sm font-medium text-gray-700"
                  >Instagram profile name</label
                >
                <input
                  type="text"
                  v-model="config.instagramHandle"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border p-2"
                />
              </div>
              <div class="col-span-6 sm:col-span-6">
                <label class="block text-sm font-medium text-gray-700"
                  >Widget Type</label
                >
                <select
                  class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md border"
                  v-model="config.widgetType"
                >
                  <option
                    v-for="(option, index) of designOptions"
                    :key="index"
                    :value="option.value"
                    >{{ option.label }}</option
                  >
                </select>
              </div>
              <div class="col-span-6 sm:col-span-3">
                <label class="block text-sm font-medium text-gray-700"
                  >Discount percentage</label
                >
                <input
                  type="number"
                  v-model="config.discount.discountPercent"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border p-2"
                />
              </div>
              <div class="col-span-6 sm:col-span-3">
                <label class="block text-sm font-medium text-gray-700"
                  >Popup delay seconds</label
                >
                <input
                  type="number"
                  v-model="config.popupDelaySeconds"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border p-2"
                />
              </div>
              <div class="col-span-6 sm:col-span-6">
                <label class="block text-sm font-medium text-gray-700"
                  >Coupon Code</label
                >
                <input
                  type="text"
                  v-model="config.discount.couponCode"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border p-2"
                />
                <p class="mt-2 text-sm text-gray-500">
                  Create coupon codes from your
                  <a class="text-blue-700" :href="discountUrl" target="_blank"
                    >Shopify admin > Discounts</a
                  >
                </p>
              </div>
            </div>

            <div class="mt-4 sm:mt-0 sm:col-span-2">
              <div class="mt-8 border-t border-gray-200 pt-5">
                <div class="flex justify-end">
                  <span class="ml-3 inline-flex rounded-md shadow-sm">
                    <button
                      type="submit"
                      class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                      @click="save"
                    >
                      Save
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Notification :text="notificationText" :show="showNotification" />
      </div>
    </div>
  </div>
</template>

<script>
import VSwitch from "../Common/VSwitch.vue";
import Notification from "../Common/Notification.vue";

export default {
  components: {
    VSwitch,
    Notification
  },
  data() {
    return {
      notificationText: "",
      showNotification: false,
      designOptions: [
        {
          label: "Popup",
          value: "popup"
        },
        {
          label: "Banner",
          value: "banner"
        }
      ]
    };
  },
  model: {
    prop: "config",
    event: "update"
  },
  props: {
    config: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  watch: {
    "config.instagramHandle": {
      handler(newVal) {
        console.log("handle changed");
        if (newVal.indexOf("@") > -1) {
          this.config.instagramHandle = newVal.replace("@", "");
        }
      }
    }
  },
  computed: {
    discountUrl() {
      const { shopOrigin } = this.$store.getters.getStore;
      return `https://${shopOrigin}/admin/discounts/`;
    }
  },
  methods: {
    handleSwitch(value) {
      if (value) {
        this.config.isEnabled = value;
        this.$store.dispatch("activateBar");
      } else {
        this.config.isEnabled = value;
        this.$store.dispatch("deactivateBar");
      }
    },
    save() {
      this.updateConfig();
    },
    updateConfig() {
      const automationConfig = { ...this.config };
      this.$store
        .dispatch("updateAppConfig", {
          requestParams: { config: automationConfig }
        })
        .then(() => {
          this.displayNotification("settings updated");
        });
    },

    displayNotification(text) {
      this.showNotification = true;
      this.notificationText = text;
      setTimeout(() => {
        this.showNotification = false;
      }, 3000);
    },
    openEmbedLink() {
      this.$store.dispatch("getEmbedUrl").then(response => {
        window.open(response.url, "_blank");
      });
    },
    deleteDiscount(index) {
      this.config.discounts.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped></style>
