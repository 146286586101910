<template>
  <!-- On: "", Off: "bg-gray-200" -->
  <span
    role="checkbox"
    tabindex="0"
    aria-checked="false"
    class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
    :class="{ 'bg-indigo-600': value, 'bg-gray-200': !value }"
    @click="$emit('input', !value)"
  >
    <!-- On: "translate-x-5", Off: "translate-x-0" -->
    <span
      aria-hidden="true"
      class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"
      :class="{ 'translate-x-5': value, 'translate-x-0': !value }"
    ></span>
  </span>
</template>

<script>
export default {
  name: "VSwitch",
  props: {
    value: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped></style>
