var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-full flex" }, [
    _c("div", { staticClass: "flex-1 min-w-0 flex flex-col overflow-hidden" }, [
      _c("main", { staticClass: "flex-1 flex overflow-hidden" }, [
        _c(
          "div",
          {
            staticClass:
              "flex-1 flex flex-col overflow-y-auto xl:overflow-hidden"
          },
          [
            _c("div", { staticClass: "flex-1 flex xl:overflow-hidden" }, [
              _c(
                "nav",
                {
                  staticClass:
                    " flex-shrink-0 w-96 bg-white border-r border-blue-gray-200 xl:flex xl:flex-col",
                  attrs: { "aria-label": "Sections" }
                },
                [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "flex-1 min-h-0 overflow-y-auto" },
                    [_c("Setup", { attrs: { config: _vm.config } })],
                    1
                  )
                ]
              ),
              _c("div", { staticClass: "flex-1 xl:overflow-y-auto" }, [
                _c(
                  "div",
                  { staticClass: "max-w-3xl mx-auto py-4 px-4 " },
                  [_c("PreviewBar", { attrs: { config: _vm.config } })],
                  1
                )
              ])
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "flex-shrink-0 h-16 px-6 border-b border-blue-gray-200 flex items-center"
      },
      [
        _c("p", { staticClass: "text-lg font-medium text-blue-gray-900" }, [
          _vm._v("Settings")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }