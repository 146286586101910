var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "max-w-3xl mx-auto my-15" },
      [
        _c("DateRangePicker", {
          attrs: { opens: "right", ranges: _vm.ranges },
          on: { update: _vm.dateSelected },
          model: {
            value: _vm.currentConfig,
            callback: function($$v) {
              _vm.currentConfig = $$v
            },
            expression: "currentConfig"
          }
        }),
        _vm.isLoading ? _c("div", [_c("Spinner")], 1) : _vm._e(),
        _c(
          "h3",
          { staticClass: "text-lg leading-6 font-medium text-gray-900 mt-4" },
          [_vm._v(" " + _vm._s(_vm.dateDiff) + " ")]
        ),
        _c(
          "h3",
          { staticClass: "text-lg leading-6 font-medium text-gray-900 mt-5" },
          [_vm._v(" Analytics ")]
        ),
        _c(
          "div",
          { staticClass: "mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3" },
          [
            _c(
              "div",
              { staticClass: "bg-white overflow-hidden shadow rounded-lg" },
              [
                _c("div", { staticClass: "px-4 py-5 sm:p-6" }, [
                  _c("dl", [
                    _c(
                      "dt",
                      {
                        staticClass:
                          "text-sm leading-5 font-medium text-gray-500 "
                      },
                      [_vm._v(" Popup Shown ")]
                    ),
                    _c(
                      "dd",
                      {
                        staticClass:
                          "mt-1 text-3xl leading-9 font-semibold text-gray-900"
                      },
                      [_vm._v(" " + _vm._s(_vm.report.popupShowed) + " ")]
                    )
                  ])
                ])
              ]
            ),
            _c(
              "div",
              { staticClass: "bg-white overflow-hidden shadow rounded-lg" },
              [
                _c("div", { staticClass: "px-4 py-5 sm:p-6" }, [
                  _c("dl", [
                    _c(
                      "dt",
                      {
                        staticClass:
                          "text-sm leading-5 font-medium text-gray-500 "
                      },
                      [_vm._v(" Follow Clicked ")]
                    ),
                    _c(
                      "dd",
                      {
                        staticClass:
                          "mt-1 text-3xl leading-9 font-semibold text-gray-900"
                      },
                      [_vm._v(" " + _vm._s(_vm.report.followClicked) + " ")]
                    )
                  ])
                ])
              ]
            ),
            _c(
              "div",
              { staticClass: "bg-white overflow-hidden shadow rounded-lg" },
              [
                _c("div", { staticClass: "px-4 py-5 sm:p-6" }, [
                  _c("dl", [
                    _c(
                      "dt",
                      {
                        staticClass:
                          "text-sm leading-5 font-medium text-gray-500 "
                      },
                      [_vm._v(" Coupon Copied ")]
                    ),
                    _c(
                      "dd",
                      {
                        staticClass:
                          "mt-1 text-3xl leading-9 font-semibold text-gray-900"
                      },
                      [_vm._v(" " + _vm._s(_vm.report.couponCopied) + " ")]
                    )
                  ])
                ])
              ]
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }