var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.config.styles
      ? _c("div", { staticClass: "py-2 max-w-3xl mx-auto" }, [
          _c(
            "div",
            { staticClass: "px-4 py-5  sm:p-6" },
            [
              _c("div", { staticClass: "md:grid md:grid-cols-3 md:gap-6" }, [
                _c("div", { staticClass: "mt-5 md:mt-0 md:col-span-3" }, [
                  _c("div", { staticClass: "grid grid-cols-6 gap-6" }, [
                    _c("div", { staticClass: "col-span-6 sm:col-span-6" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "block text-sm font-medium text-gray-700",
                          attrs: { for: "email-address" }
                        },
                        [_vm._v("CTA")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.config.discount.cta,
                            expression: "config.discount.cta"
                          }
                        ],
                        staticClass:
                          "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border p-2",
                        attrs: { type: "text" },
                        domProps: { value: _vm.config.discount.cta },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.config.discount,
                              "cta",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "col-span-6 sm:col-span-6" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "block text-sm font-medium text-gray-700",
                          attrs: { for: "email-address" }
                        },
                        [_vm._v("Goal Completion text")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.config.discount.goalCompletionText,
                            expression: "config.discount.goalCompletionText"
                          }
                        ],
                        staticClass:
                          "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border p-2",
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.config.discount.goalCompletionText
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.config.discount,
                              "goalCompletionText",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "col-span-6 sm:col-span-3" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "block text-sm font-medium text-gray-700",
                          attrs: { for: "email-address" }
                        },
                        [_vm._v("Follow Button Label")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.config.discount.followButtonLabel,
                            expression: "config.discount.followButtonLabel"
                          }
                        ],
                        staticClass:
                          "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border p-2",
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.config.discount.followButtonLabel
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.config.discount,
                              "followButtonLabel",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "col-span-6 sm:col-span-3" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "block text-sm font-medium text-gray-700",
                          attrs: { for: "email-address" }
                        },
                        [_vm._v("Copy Button Label")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.config.discount.copyButtonLabel,
                            expression: "config.discount.copyButtonLabel"
                          }
                        ],
                        staticClass:
                          "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border p-2",
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.config.discount.copyButtonLabel
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.config.discount,
                              "copyButtonLabel",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "col-span-6 sm:col-span-3" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "block text-sm font-medium text-gray-700",
                          attrs: { for: "email-address" }
                        },
                        [_vm._v("Copied Button Label")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.config.discount.copiedButtonLabel,
                            expression: "config.discount.copiedButtonLabel"
                          }
                        ],
                        staticClass:
                          "mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md border p-2",
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.config.discount.copiedButtonLabel
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.config.discount,
                              "copiedButtonLabel",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("hr", { staticClass: "col-span-6" }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-span-3 sm:col-span-3 lg:col-span-3 relative"
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass:
                              "block text-sm font-medium leading-5 text-gray-700",
                            attrs: { for: "city" }
                          },
                          [_vm._v("Popup Background")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.config.styles.popupBackgroundColor,
                              expression: "config.styles.popupBackgroundColor"
                            }
                          ],
                          staticClass:
                            "mt-1 form-input block w-full py-2 px-12 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5",
                          domProps: {
                            value: _vm.config.styles.popupBackgroundColor
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.config.styles,
                                "popupBackgroundColor",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("verte", {
                          staticClass: "colorPicker",
                          attrs: { picker: "square", model: "hex" },
                          model: {
                            value: _vm.config.styles.popupBackgroundColor,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.config.styles,
                                "popupBackgroundColor",
                                $$v
                              )
                            },
                            expression: "config.styles.popupBackgroundColor"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-span-3 sm:col-span-3 lg:col-span-3 relative"
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass:
                              "block text-sm font-medium leading-5 text-gray-700",
                            attrs: { for: "city" }
                          },
                          [_vm._v("Popup Text Color")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.config.styles.popupTextColor,
                              expression: "config.styles.popupTextColor"
                            }
                          ],
                          staticClass:
                            "mt-1 form-input block w-full py-2 px-12 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5",
                          domProps: { value: _vm.config.styles.popupTextColor },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.config.styles,
                                "popupTextColor",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("verte", {
                          staticClass: "colorPicker",
                          attrs: { picker: "square", model: "hex" },
                          model: {
                            value: _vm.config.styles.popupTextColor,
                            callback: function($$v) {
                              _vm.$set(_vm.config.styles, "popupTextColor", $$v)
                            },
                            expression: "config.styles.popupTextColor"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-span-3 sm:col-span-3 lg:col-span-3 relative"
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass:
                              "block text-sm font-medium leading-5 text-gray-700",
                            attrs: { for: "city" }
                          },
                          [_vm._v("Button Background ")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.config.styles.buttonBackgroundColor,
                              expression: "config.styles.buttonBackgroundColor"
                            }
                          ],
                          staticClass:
                            "mt-1 form-input block w-full py-2 px-12 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5",
                          domProps: {
                            value: _vm.config.styles.buttonBackgroundColor
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.config.styles,
                                "buttonBackgroundColor",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("verte", {
                          staticClass: "colorPicker",
                          attrs: { picker: "square", model: "hex" },
                          model: {
                            value: _vm.config.styles.buttonBackgroundColor,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.config.styles,
                                "buttonBackgroundColor",
                                $$v
                              )
                            },
                            expression: "config.styles.buttonBackgroundColor"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-span-3 sm:col-span-3 lg:col-span-3 relative"
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass:
                              "block text-sm font-medium leading-5 text-gray-700",
                            attrs: { for: "city" }
                          },
                          [_vm._v("Button Text Color")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.config.styles.buttonTextColor,
                              expression: "config.styles.buttonTextColor"
                            }
                          ],
                          staticClass:
                            "mt-1 form-input block w-full py-2 px-12 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5",
                          domProps: {
                            value: _vm.config.styles.buttonTextColor
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.config.styles,
                                "buttonTextColor",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("verte", {
                          staticClass: "colorPicker",
                          attrs: { picker: "square", model: "hex" },
                          model: {
                            value: _vm.config.styles.buttonTextColor,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.config.styles,
                                "buttonTextColor",
                                $$v
                              )
                            },
                            expression: "config.styles.buttonTextColor"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "mt-4 sm:mt-0 sm:col-span-2" }, [
                    _c(
                      "div",
                      { staticClass: "mt-8 border-t border-gray-200 pt-5" },
                      [
                        _c("div", { staticClass: "flex justify-end" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "ml-3 inline-flex rounded-md shadow-sm"
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out",
                                  attrs: { type: "submit" },
                                  on: { click: _vm.save }
                                },
                                [_vm._v(" Save ")]
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ]),
              _c("Notification", {
                attrs: {
                  text: _vm.notificationText,
                  show: _vm.showNotification
                }
              })
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }