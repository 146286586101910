var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "bg-gray-800" }, [
    _c("div", { staticClass: "max-w-7xl mx-auto px-2 sm:px-6 lg:px-8" }, [
      _c(
        "div",
        { staticClass: "relative flex items-center justify-between h-16" },
        [
          _c(
            "div",
            {
              staticClass:
                "absolute inset-y-0 left-0 flex items-center sm:hidden"
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out",
                  attrs: {
                    "aria-label": "Main menu",
                    "aria-expanded": "false"
                  },
                  on: {
                    click: function($event) {
                      _vm.isMenuOpen = !_vm.isMenuOpen
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: " h-6 w-6",
                      class: { block: !_vm.isMenuOpen, hidden: _vm.isMenuOpen },
                      attrs: {
                        stroke: "currentColor",
                        fill: "none",
                        viewBox: "0 0 24 24"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          "stroke-width": "2",
                          d: "M4 6h16M4 12h16M4 18h16"
                        }
                      })
                    ]
                  ),
                  _c(
                    "svg",
                    {
                      staticClass: " h-6 w-6",
                      class: { block: _vm.isMenuOpen, hidden: !_vm.isMenuOpen },
                      attrs: {
                        stroke: "currentColor",
                        fill: "none",
                        viewBox: "0 0 24 24"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          "stroke-width": "2",
                          d: "M6 18L18 6M6 6l12 12"
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "flex-1 flex items-center justify-center sm:items-stretch sm:justify-start"
            },
            [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "hidden md:ml-6 md:flex md:items-center" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out",
                      attrs: {
                        to: "/settings",
                        "exact-active-class": "text-white bg-gray-900"
                      }
                    },
                    [_vm._v("Settings ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out relative",
                      attrs: {
                        to: "/customize",
                        "exact-active-class": "text-white bg-gray-900"
                      }
                    },
                    [_vm._v("Customize ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out relative",
                      attrs: {
                        to: "/analytics",
                        "exact-active-class": "text-white bg-gray-900"
                      }
                    },
                    [_vm._v("Analytics ")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
            },
            [
              _c("ProfileDropdown", {
                on: {
                  showOnboarding: function($event) {
                    return _vm.$emit("showOnboarding")
                  }
                }
              })
            ],
            1
          )
        ]
      )
    ]),
    _c(
      "div",
      {
        staticClass: " sm:hidden",
        class: { block: _vm.isMenuOpen, hidden: !_vm.isMenuOpen }
      },
      [
        _c(
          "div",
          { staticClass: "px-2 pt-2 pb-3" },
          [
            _c(
              "router-link",
              {
                staticClass:
                  "block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out",
                attrs: { to: "/settings" }
              },
              [_vm._v("Settings ")]
            ),
            _c(
              "router-link",
              {
                staticClass:
                  "block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out",
                attrs: { to: "/customize" }
              },
              [_vm._v("Customize ")]
            ),
            _c(
              "router-link",
              {
                staticClass:
                  "block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out",
                attrs: { to: "/analytics" }
              },
              [_vm._v("Analytics ")]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-shrink-0 items-center flex" }, [
      _c("p", { staticClass: "text-white  flex items-center font-bold" }, [
        _vm._v(" Insta follow booster ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }