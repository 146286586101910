var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-center z-50"
    },
    [
      _c(
        "transition",
        {
          attrs: {
            "enter-active-class": "transform ease-out duration-300 transition",
            "enter-class":
              "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2",
            "enter-to-class": "translate-y-0 opacity-100 sm:translate-x-0",
            "leave-active-class": "transition ease-in duration-100",
            "leave-class": "opacity-100",
            "leave-to-class": "opacity-0"
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.show,
                  expression: "show"
                }
              ],
              staticClass:
                "max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto "
            },
            [
              _c(
                "div",
                { staticClass: "rounded-lg shadow-xs overflow-hidden" },
                [
                  _c("div", { staticClass: "p-4" }, [
                    _c("div", { staticClass: "flex items-start" }, [
                      _c("div", { staticClass: "flex-shrink-0" }, [
                        _c(
                          "svg",
                          {
                            staticClass: "h-6 w-6 text-green-400",
                            attrs: {
                              fill: "none",
                              viewBox: "0 0 24 24",
                              stroke: "currentColor"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                                "stroke-width": "2",
                                d:
                                  "M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                              }
                            })
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "ml-3 w-0 flex-1 pt-0.5" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-sm leading-5 font-medium text-gray-900"
                          },
                          [_vm._v(" " + _vm._s(_vm.text) + " ")]
                        )
                      ]),
                      _c("div", { staticClass: "ml-4 flex-shrink-0 flex" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "h-5 w-5",
                                attrs: {
                                  viewBox: "0 0 20 20",
                                  fill: "currentColor"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    "fill-rule": "evenodd",
                                    d:
                                      "M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z",
                                    "clip-rule": "evenodd"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ])
                    ])
                  ])
                ]
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }