<template>
  <div>
    <div class="relative inline-block text-left">
      <div>
        <span class="rounded-md shadow-sm">
          <button
            @click="isOpen = !isOpen"
            class="flex items-center text-gray-400 hover:text-gray-600"
          >
            <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
              <path
                d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
              ></path>
            </svg>
          </button>
        </span>
      </div>

      <transition
        enter-active-class="transition ease-out duration-100 transform"
        enter-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75 transform"
        leave-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95"
      >
        <div
          class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg z-10"
          v-show="isOpen"
        >
          <div
            class="rounded-md bg-white shadow-xs"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <div class="py-1">
              <a
                class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                role="menuitem"
              >
                <div
                  id="google_translate_element"
                  class="hidden md:block"
                ></div>
              </a>

              <a
                class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                role="menuitem"
                href="mailto:help@carthike.com"
              >
                Contact us
              </a>
            </div>
            <div class="border-t border-gray-100"></div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileDropdown",
  data() {
    return {
      isOpen: false
    };
  },
  computed: {
    store() {
      return this.$store.getters.getStore;
    }
  },
  created() {
    window.addEventListener("click", this.handleOutsideClick);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.handleOutsideClick);
  },
  methods: {
    handleOutsideClick(e) {
      // close dropdown when clicked outside
      if (!this.$el.contains(e.target)) {
        this.dropDropdown = false;
        this.isOpen = false;
      }
    },
    gotoPlan() {
      this.$router.push({ name: "Plan" });
    },
    gotoOnboarding() {
      this.$emit("showOnboarding");
    }
  }
};
</script>

<style lang="scss" scoped></style>
