<template>
  <div class="home antialiased font-sans  bg-gray-100 h-full">
    <Topbar @showOnboarding="showOnboardingModal = true" />
    <router-view></router-view>
  </div>
</template>

<script>
// @ is an alias to /src
import Topbar from "@/components/Common/Topbar.vue";

export default {
  name: "Home",
  components: {
    Topbar
  },
  data() {
    return {
      isPlanExceeded: false
    };
  },
  async created() {
    const authToken = this.$route.query.token;
    const existingToken = window.localStorage.getItem("authToken");
    if (authToken) {
      window.localStorage.setItem("authToken", authToken);
    } else {
      if (!existingToken) {
        this.$router.push({ name: "Login" });
        return;
      }
    }
    const response = await this.$store.dispatch("getConfig");
    await this.$store.dispatch("getAppConfig");
    const store = response.store;
    const route = this.$route.path;
    console.log("route", route);
    this.setEmailOnCrisp();
    this.checkForPlanLimitExceeded();
    if (store && store.isPlanSelected) {
      if (route === "/") {
        this.$router.push({ name: "Settings" });
        return;
      }
    }

    if (store && store.isPlanSelected === false) {
      this.$router.push({ name: "Plan" });
    }
  },
  methods: {
    onModalClose() {
      this.showOnboardingModal = false;
    },
    parseJwt(token) {
      var base64Payload = token.split(".")[1];
      var payload = Buffer.from(base64Payload, "base64");
      return JSON.parse(payload.toString());
    },
    setEmailOnCrisp() {
      const token = window.localStorage.getItem("authToken");
      if (token) {
        const payload = this.parseJwt(token);
        // eslint-disable-next-line
        if (payload && payload.email && $crisp) {
          $crisp.push(["set", "user:email", [payload.email]]); // eslint-disable-line
          const store = this.$store.getters.getStore;
          const config = this.$store.getters.getConfig;
          // eslint-disable-next-line
          $crisp.push([
            "set",
            "session:data",
            [
              [
                ["plan", store.plan],
                ["shopOrigin", store.shopOrigin],
                ["storeId", store.id],
                ["widgetEnabled", config.isEnabled],
                ["widgetType", config.widgetType],
                ["instagramHandle", config.instagramHandle]
              ]
            ]
          ]);
        }
      }
    },
    checkForPlanLimitExceeded() {
      const store = this.$store.getters.getStore;
      const billing = this.$store.getters.getBilling;
      if (
        store &&
        store.plan === "limited_free" &&
        billing.messagesConsumed >= billing.messagesLimit
      ) {
        this.isPlanExceeded = true;
      }
    }
  }
};
</script>
