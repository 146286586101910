<template>
  <div>
    <div class="max-w-3xl mx-auto my-15">
      <DateRangePicker
        v-model="currentConfig"
        opens="right"
        :ranges="ranges"
        @update="dateSelected"
      />
      <div v-if="isLoading">
        <Spinner />
      </div>
      <h3 class="text-lg leading-6 font-medium text-gray-900 mt-4">
        {{ dateDiff }}
      </h3>
      <h3 class="text-lg leading-6 font-medium text-gray-900 mt-5">
        Analytics
      </h3>
      <div class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        <div class="bg-white overflow-hidden shadow rounded-lg">
          <div class="px-4 py-5 sm:p-6">
            <dl>
              <dt class="text-sm leading-5 font-medium text-gray-500 ">
                Popup Shown
              </dt>
              <dd class="mt-1 text-3xl leading-9 font-semibold text-gray-900">
                {{ report.popupShowed }}
              </dd>
            </dl>
          </div>
        </div>
        <div class="bg-white overflow-hidden shadow rounded-lg">
          <div class="px-4 py-5 sm:p-6">
            <dl>
              <dt class="text-sm leading-5 font-medium text-gray-500 ">
                Follow Clicked
              </dt>
              <dd class="mt-1 text-3xl leading-9 font-semibold text-gray-900">
                {{ report.followClicked }}
              </dd>
            </dl>
          </div>
        </div>
        <div class="bg-white overflow-hidden shadow rounded-lg">
          <div class="px-4 py-5 sm:p-6">
            <dl>
              <dt class="text-sm leading-5 font-medium text-gray-500 ">
                Coupon Copied
              </dt>
              <dd class="mt-1 text-3xl leading-9 font-semibold text-gray-900">
                {{ report.couponCopied }}
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "vue-simple-spinner";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import {
  subDays,
  format,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth
} from "date-fns";

export default {
  name: "Analytics",
  components: {
    DateRangePicker,
    Spinner
  },
  data() {
    return {
      report: {},
      currentConfig: {
        startDate: "",
        endDate: ""
      },
      ranges: {},
      isLoading: false
    };
  },
  computed: {
    dateDiff() {
      const startDate = this.currentConfig.startDate;
      const endDate = this.currentConfig.endDate;

      if (startDate && endDate) {
        return `${format(startDate, "dd MMM yyyy")} - ${format(
          endDate,
          "dd MMM yyyy"
        )}`;
      }
      return "";
    },
    config() {
      return this.$store.getters.getConfig;
    }
  },
  async created() {
    let today = new Date();
    today.setHours(0, 0, 0, 0);

    let yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);
    this.ranges = {
      Today: [today, today],
      Yesterday: [yesterday, yesterday],
      "This month": [startOfMonth(today), endOfMonth(today)],

      "Last month": [
        new Date(today.getFullYear(), today.getMonth() - 1, 1),
        new Date(today.getFullYear(), today.getMonth(), 0)
      ]
    };
  },
  mounted() {
    const startDate = subDays(new Date(), 7);
    this.currentConfig = {
      startDate: startDate,
      endDate: new Date()
    };
    this.getReport();
  },
  methods: {
    dateSelected() {
      this.getReport();
    },
    async getReport() {
      this.isLoading = true;
      const startDate =
        startOfDay(this.currentConfig.startDate).getTime() / 1000;
      const endDate = endOfDay(this.currentConfig.endDate).getTime() / 1000;
      const response = await this.$store.dispatch("getAnalytics", {
        startDate,
        endDate
      });
      this.report = response.report;

      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss">
.input-date {
  border-radius: 4px;
  font-size: 13px !important;
  font-family: inter;
  font-weight: 400;
}
.daterangepicker .calendars {
  display: flex;
}
</style>
